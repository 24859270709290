import loadingImg from "../../assets/loading.svg";

const Loading = () => {
  return (
    <div className="text-center w-100">
      <img src={loadingImg} alt="Cargando..." />
    </div>
  );
};

export default Loading;
