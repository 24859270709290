import { Carousel, Col, Container, Row } from "react-bootstrap";
import slider from "../../assets/projects/2024/trenes/slider.png";
import img01 from "../../assets/projects/2024/trenes/01.png";
import img02 from "../../assets/projects/2024/trenes/02.png";
import img03 from "../../assets/projects/2024/trenes/03.png";
import img04 from "../../assets/projects/2024/trenes/04.png";
import img05 from "../../assets/projects/2024/trenes/05.png";
import img06 from "../../assets/projects/2024/trenes/06.png";
import video from "../../assets/projects/2024/trenes/Videos/01.mp4";
import { useContext, useEffect, useRef } from "react";

import ReactTranslated from "react-translated";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { UserProvider } from "../../router/AppProvider";

const Project_Trenes = () => {
  const title = "Trenes Argentinos";

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  const { language } = useContext(UserProvider);

  return (
    <>
      <Container className="container_projects p-0 m-0">
        <Row className="pb-md-5 pb-4">
          <Col>
            <img src={slider} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={3} xs={11} className="mx-auto">
            {language === "en" ? (
              <p className="subtituloProject">
                Streamlining the <span className="tituloProject">Trenes Argentinos </span>experience
              </p>
            ) : (
              <p className="subtituloProject">
                Agilizando la Experiencia de <span className="tituloProject">Trenes Argentinos</span>{" "}
              </p>
            )}
          </Col>
          <Col md={9} xs={0} className="mx-auto" />{" "}
        </Row>
        <Row className="pb-md-5 pb-4 ">
          <Col>
            <img src={img01} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={0} className="mx-auto" />
          <Col md={6} xs={11} className="mx-auto">
            <p>
              <ReactTranslated.Translate text="trenes3" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={12} xs={12} className="mx-auto">
            <Carousel>
              <Carousel.Item>
                <img src={img02} alt={title} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={img03} alt={title} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={img04} alt={title} />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>

        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={0} className="mx-auto" />
          <Col md={6} xs={11} className="mx-auto">
            <p>
              <ReactTranslated.Translate text="trenes4" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={12}>
            <img src={img05} alt={title} />
          </Col>
          <Col md={6} xs={12}>
            <img src={img06} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            {" "}
            <div className="text-center ">
              <video
                style={{ maxWidth: "100%", margin: "0 auto" }}
                playsInline
                loop
                muted
                alt="WOP"
                src={video}
                ref={videoEl}
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject241" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              {" "}
              <ReactTranslated.Translate text="trenes5" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject242" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="trenes6" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject243" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="trenes7" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject244" />
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              <ReactTranslated.Translate text="trenes8" />
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between pt-md-5 pb-md-5 pt-4 pb-4 pe-md-5 pe-2">
            <Link to="/projects/zurich" className="linkNext">
              <span>{"<<"}</span> <ReactTranslated.Translate text="linkback" />
            </Link>

            <Link to="/projects/aerolineas" className="linkNext rightN">
              <ReactTranslated.Translate text="linknext" /> >>
            </Link>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Project_Trenes;
