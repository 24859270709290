import { Carousel, Col, Container, Row } from "react-bootstrap";
import slider from "../../assets/projects/2024/benomad/slider.png";
import img01 from "../../assets/projects/2024/benomad/01.png";
import img02 from "../../assets/projects/2024/benomad/02.png";
import img03 from "../../assets/projects/2024/benomad/03.png";
import img04 from "../../assets/projects/2024/benomad/04.png";
import carousel1 from "../../assets/projects/2024/benomad/carousel1.png";
import carousel2 from "../../assets/projects/2024/benomad/carousel2.png";
import carousel3 from "../../assets/projects/2024/benomad/carousel3.png";
import carousel4 from "../../assets/projects/2024/benomad/carousel4.png";
import carouselbottom1 from "../../assets/projects/2024/benomad/carousel-bottom-1.png";
import carouselbottom2 from "../../assets/projects/2024/benomad/carousel-bottom-2.png";
import carouselbottom3 from "../../assets/projects/2024/benomad/carousel-bottom-3.png";
import carouselbottom4 from "../../assets/projects/2024/benomad/carousel-bottom-4.png";
import video1 from "../../assets/projects/2024/benomad/Videos/01.mp4";
import video2 from "../../assets/projects/2024/benomad/Videos/02.mp4";
import { useEffect, useRef } from "react";

import ReactTranslated from "react-translated";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const Project_Benomad = () => {
  const title = "Benomad";

  const videoEl = useRef(null);
  const videoEl2 = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
    videoEl2 &&
      videoEl2.current &&
      videoEl2.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <Container className="container_projects p-0 m-0">
        <Row className="pb-md-5 pb-4">
          <Col>
            <img src={slider} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={12} xs={11} className="mx-auto">
            <p className="txt26 fw-bold">benomad</p>
            <p>
              <ReactTranslated.Translate text="benomad1" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <div className="text-center ">
              <video
                style={{ maxWidth: "100%", margin: "0 auto" }}
                playsInline
                loop
                muted
                autoPlay
                alt="WOP"
                src={video1}
                ref={videoEl}
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={0} className="mx-auto" />
          <Col md={6} xs={11} className="mx-auto">
            <p>
              <ReactTranslated.Translate text="benomad2" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <img src={img01} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={12} className="pb-md-0 pb-5">
            <img src={img02} alt={title} />
          </Col>
          <Col md={6} xs={12}>
            <img src={img03} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={0} className="mx-auto" />
          <Col md={6} xs={11} className="mx-auto">
            <p>
              <ReactTranslated.Translate text="benomad3" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <Carousel>
              <Carousel.Item>
                <img src={carousel1} alt={title} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel2} alt={title} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel3} alt={title} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carousel4} alt={title} />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <div className="text-center ">
              <video
                style={{ maxWidth: "100%", margin: "0 auto" }}
                playsInline
                loop
                muted
                autoPlay
                alt="WOP"
                src={video2}
                ref={videoEl}
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <img src={img04} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <iframe
              className="video-frame"
              width="100%"
              height="720"
              src="https://www.youtube.com/embed/_mPv6aGBX74"
              title="Bienvenido a la nueva forma de trabajar. Bienvenido a benomad. Tu oficina donde quieras."
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>

        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <Carousel>
              <Carousel.Item>
                <img src={carouselbottom1} alt={title} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carouselbottom2} alt={title} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carouselbottom3} alt={title} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={carouselbottom4} alt={title} />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject241" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              {" "}
              <ReactTranslated.Translate text="benomad4" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject242" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="benomad5" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject243" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="benomad6" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject244" />
            </p>
            <p style={{ whiteSpace: "pre-line" }}>2021</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between pt-md-5 pb-md-5 pt-4 pb-4 pe-md-5 pe-2">
            <Link to="/projects/bdo" className="linkNext">
              <span>{"<<"}</span> <ReactTranslated.Translate text="linkback" />
            </Link>
            <Link to="/projects/flip" className="linkNext rightN">
              <ReactTranslated.Translate text="linknext" /> >>
            </Link>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Project_Benomad;
