import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { Col, Row } from "react-bootstrap";
import loadingImg from "../../assets/loadingImg.svg";

const ContactForm = ({ language = "en" }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    company: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [okSent, setOkSent] = useState(null);

  const translations = {
    en: {
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone Number",
      email: "Email",
      company: "Company",
      message: "Write your message..",
      submit: "Let’s Connect!",
      required: "is required",
      phoneInvalid: "Phone number must be 10 digits",
      emailInvalid: "Email is invalid",
      success: "Email sent successfully!",
      failure: "Failed to send email. Please try again later.",
    },
    es: {
      firstName: "Nombre",
      lastName: "Apellido",
      phone: "Número de teléfono",
      email: "Correo electrónico",
      company: "Empresa",
      message: "Escribe tu mensaje...",
      submit: "¡Conectemos!",
      required: "es obligatorio",
      phoneInvalid: "El número de teléfono debe tener 10 dígitos",
      emailInvalid: "El correo electrónico es inválido",
      success: "¡Correo enviado exitosamente!",
      failure: "Error al enviar el correo. Por favor, inténtelo de nuevo más tarde.",
    },
  };

  const t = translations[language];

  const validate = () => {
    let validationErrors = {};

    if (!formData.firstName.trim()) {
      validationErrors.firstName = `${t.firstName} ${t.required}`;
    }
    if (!formData.lastName.trim()) {
      validationErrors.lastName = `${t.lastName} ${t.required}`;
    }
    if (!formData.phone.trim()) {
      validationErrors.phone = `${t.phone} ${t.required}`;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      validationErrors.phone = t.phoneInvalid;
    }
    if (!formData.email.trim()) {
      validationErrors.email = `${t.email} ${t.required}`;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      validationErrors.email = t.emailInvalid;
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    try {
      await emailjs.send("service_wop", "template_wop", formData, "user_9LvDgXfdZLXUCssd2jjnf");
      setLoading(false);
      setOkSent(t.success);
    } catch (error) {
      setLoading(false);
      console.error("Failed to send email:", error);
      alert(t.failure);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setOkSent(null);
    }, 5000);
  }, [okSent]);

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: "400px", margin: "0 auto" }}>
      <Row>
        <Col md={6} xs={12} className="pt-4">
          {" "}
          <div>
            <input
              type="text"
              name="firstName"
              placeholder={t.firstName}
              value={formData.firstName}
              onChange={handleChange}
              className="form-control"
            />
            {errors.firstName && <span className="errorspan">{errors.firstName}</span>}
          </div>
        </Col>
        <Col md={6} xs={12} className="pt-4">
          {" "}
          <div>
            <input
              type="text"
              name="lastName"
              placeholder={t.lastName}
              value={formData.lastName}
              onChange={handleChange}
              className="form-control"
            />
            {errors.lastName && <span className="errorspan">{errors.lastName}</span>}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12} className="pt-4">
          {" "}
          <div>
            <input
              type="text"
              name="phone"
              placeholder={t.phone}
              value={formData.phone}
              onChange={handleChange}
              className="form-control"
            />
            {errors.phone && <span className="errorspan">{errors.phone}</span>}
          </div>
        </Col>
        <Col md={6} xs={12} className="pt-4">
          {" "}
          <div>
            <input
              type="email"
              name="email"
              placeholder={t.email}
              value={formData.email}
              onChange={handleChange}
              className="form-control"
            />
            {errors.email && <span className="errorspan">{errors.email}</span>}
          </div>
        </Col>
      </Row>

      <div className="pt-4">
        <input
          type="text"
          name="company"
          placeholder={t.company}
          value={formData.company}
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <div className="pt-4 pb-4">
        <textarea
          name="message"
          placeholder={t.message}
          value={formData.message}
          onChange={handleChange}
          className="form-control textareanew"
          rows="3"
        ></textarea>
      </div>

      {okSent && <div className="text-center pt-3 pb-3">{okSent}</div>}

      {loading ? (
        <div className="text-center pt-3 pb-3">
          <img src={loadingImg} alt="Loading" />
        </div>
      ) : (
        <button type="submit" className="btn btn-primary float-end" disabled={loading ? true : false}>
          {t.submit}
        </button>
      )}
    </form>
  );
};

export default ContactForm;
