import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Slider from "../components/About/Slider/Slider";
import WhoWeAre from "../components/About/WhoWeAre/WhoWeAre";

const About = () => {
  return (
    <>
      <Slider />
      <WhoWeAre />
      <Footer />
    </>
  );
};

export default About;
