import iconLinkedin from "../../../assets/team/iconLinkedin.png";

const ProfileItem = ({ img, name, title, link }) => {
  return (
    <div className="box-profile text-center">
      <div className="text-center pb-3">
        <img src={img} alt={name} />
      </div>
      <div className="text-center pb-1">
        <h5>{name}</h5>
      </div>
      <div className="text-center pb-1">
        <h6 style={{ display: "block", whiteSpace: "pre-wrap" }}>{title}</h6>
      </div>
      <div className="text-center pb-1">
        <a href={link} target="_blank" rel="noreferrer">
          <img src={iconLinkedin} className="img-fluid" alt="Linkedin" />
        </a>
      </div>
    </div>
  );
};

export default ProfileItem;
