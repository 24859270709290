import "./styles/styles.scss";
import { AppProvider } from "./router/AppProvider";
import PreRouter from "./PreRouter";
import { gtag, install } from "ga-gtag";

function App() {
  install("UA-119291951-1");

  return (
    <AppProvider>
      <PreRouter />
    </AppProvider>
  );
}

export default App;
