import Carousel from "react-bootstrap/Carousel";
import imgSlider from "../../../assets/about/slider-about.png";
import videoSlider from "../../../assets/about/animated/animatedmp4.mp4";
import videoSlider_mobile from "../../../assets/about/animated/animatedmp4_mobile.mp4";
import { useEffect, useRef } from "react";

const Slider = () => {
  const videoEl = useRef(null);
  const videoEl_mobile = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  const attemptPlay_mobile = () => {
    videoEl_mobile &&
      videoEl_mobile.current &&
      videoEl_mobile.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
    attemptPlay_mobile();
  }, []);

  return (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item>
        {/* <img className="d-block w-100" src={imgSlider} alt="First slide" /> */}
        <div className="text-center d-md-block d-none">
          <video
            style={{ maxWidth: "100%", margin: "0 auto" }}
            playsInline
            loop
            muted
            alt="WOP"
            src={videoSlider}
            ref={videoEl}
          />
        </div>
        <div className="text-center d-block d-md-none">
          <video
            style={{ maxWidth: "100%", margin: "0 auto" }}
            playsInline
            loop
            muted
            alt="WOP"
            src={videoSlider_mobile}
            ref={videoEl_mobile}
          />
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
