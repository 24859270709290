import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/logo_white.png";
import up from "../../assets/up.png";
import { Link } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import * as ReactTranslated from "react-translated";

const Footer = () => {
  return (
    <Container fluid className="footer pt-5 pb-5">
      <Container>
        <Row>
          <Col md={3} xs={11} className="mx-auto pb-5">
            <img src={logo} className="img-fluid" />
          </Col>
          <Col md={3} xs={11} className="mx-auto">
            <ul className="list-unstyled">
              <li>
                <Link to="/projects" className="linkFooter">
                  <b>
                    {" "}
                    <ReactTranslated.Translate text="menuOption1" />
                  </b>
                </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/about" className="linkFooter">
                  <b>
                    {" "}
                    <ReactTranslated.Translate text="menuOption2" />
                  </b>
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/team" className="linkFooter">
                  <b>
                    {" "}
                    <ReactTranslated.Translate text="menuOption3" />
                  </b>
                </Link>
              </li>

              <li>
                <Link to="/contact" className="linkFooter">
                  <b>
                    {" "}
                    <ReactTranslated.Translate text="menuOption4" />
                  </b>
                </Link>
              </li>
            </ul>

            <ul className="list-unstyled">
              <li>
                <b>
                  {" "}
                  <ReactTranslated.Translate text="titleFollowUs" />
                </b>
              </li>
              <li>
                {" "}
                <a
                  href="https://www.linkedin.com/company/wop/"
                  className="linkFooter"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Linkedin{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="https://www.instagram.com/wopba/" className="linkFooter" target={"_blank"} rel="noreferrer">
                  Instagram{" "}
                </a>
              </li>
              <li>
                {" "}
                <a href="https://www.facebook.com/WopBA/" className="linkFooter" target={"_blank"} rel="noreferrer">
                  Facebook{" "}
                </a>
              </li>
            </ul>
          </Col>
          <Col md={6} xs={11} className="mx-auto">
            <Row>
              <Col md={6} xs={12}>
                <ul className="list-unstyled mb-0">
                  <li className="">
                    <b className="fw-bold">
                      <ReactTranslated.Translate text="titlefindUs" />
                    </b>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <ul className="list-unstyled">
                  <li>
                    Basavilbaso 1350 - Of. 603
                    <br /> CABA - Buenos Aires
                  </li>
                  <li>
                    <a
                      href="https://goo.gl/maps/yjkDZxsuR9Kx6bWZ6"
                      className="linkFooter"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <b className="fw-bold">Google Maps +</b>
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={6} xs={12}>
                <ul className="list-unstyled">
                  <li>
                    José Maria Ibarrarán 72 piso 8,
                    <br /> Col San josé Insurgentes.
                    <br /> Ciudad de México - México
                  </li>
                  <li>
                    <a
                      href="https://maps.app.goo.gl/vRAtAs1MEqCamrb58"
                      className="linkFooter"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <b className="fw-bold">Google Maps +</b>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <ul className="list-unstyled">
                  <li>
                    <b className="fw-bold">
                      {" "}
                      <ReactTranslated.Translate text="titleEmailUs" />
                    </b>
                  </li>
                  <li>
                    <a href="mailto:info@wopdigital.com.ar" className="linkFooter">
                      info@wopdigital.com.ar
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md={6} xs={12}></Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <div>
              <a href="#top">
                <img src={up} className="img-fluid" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
