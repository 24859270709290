import { Col, Container, Row } from "react-bootstrap";
import slider from "../../assets/projects/2024/bdo/slider.png";
import img01 from "../../assets/projects/2024/bdo/01.png";
import img02 from "../../assets/projects/2024/bdo/02.png";

import ReactTranslated from "react-translated";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const Project_BDO = () => {
  const title = "BDO";

  return (
    <>
      <Container className="container_projects p-0 m-0">
        <Row className="pb-md-5 pb-4">
          <Col>
            <img src={slider} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={12} xs={11} className="mx-auto">
            <p className="tituloProject">
              <ReactTranslated.Translate text="bdo1" />
            </p>
            <p className="subtituloProject">
              <ReactTranslated.Translate text="bdo2" />
            </p>
          </Col>
        </Row>
        <Row className="pb-md-5 pb-4">
          <Col>
            <img src={img01} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={10} className="offset-md-6 offset-1 ">
            <p>
              <ReactTranslated.Translate text="bdo3" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <img src={img02} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={10} className="offset-md-6 offset-1 ">
            <p>
              <ReactTranslated.Translate text="bdo4" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <iframe
              className="video-frame"
              width="100%"
              height="720"
              src="https://www.youtube.com/embed/f-mxN-xYPnM"
              title="Bdo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject241" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              {" "}
              <ReactTranslated.Translate text="bdo5" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject242" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="bdo6" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject243" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="bdo7" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject244" />
            </p>
            <p style={{ whiteSpace: "pre-line" }}>2020</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between pt-md-5 pb-md-5 pt-4 pb-4 pe-md-5 pe-2">
            <Link to="/projects/aerolineas" className="linkNext">
              <span>{"<<"}</span> <ReactTranslated.Translate text="linkback" />
            </Link>
            <Link to="/projects/benomad" className="linkNext rightN">
              <ReactTranslated.Translate text="linknext" /> >>
            </Link>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Project_BDO;
