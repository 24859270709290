import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import SliderTeam from "../components/Team/SliderTeam/SliderTeam";
import ProfileList from "../components/Team/Profile/ProfileList";
import TeamFooter from "../components/Team/TeamFooter/TeamFooter";

const Team = () => {
  return (
    <>
      <SliderTeam />
      <ProfileList />
      <TeamFooter />
      <Footer />
    </>
  );
};

export default Team;
