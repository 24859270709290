import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ContactView from "../components/Contact/ContactView";

const Contact = () => {
  return (
    <>
      <ContactView />
      <Footer />
    </>
  );
};

export default Contact;
