import "./styles/styles.scss";
import Routes from "./router/routes";
import { Provider } from "react-translated";
import translation from "./translation/translation";
import { useContext } from "react";
import { UserProvider } from "./router/AppProvider";

function PreRouter() {
  const { language } = useContext(UserProvider);
  console.log(language, "pre router");
  return (
    <Provider language={language} translation={translation}>
      <Routes />
    </Provider>
  );
}

export default PreRouter;
