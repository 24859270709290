import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../pages/home";
import ScrollToTop from "../hooks/ScrollToTop";
import About from "../pages/about";
import Team from "../pages/team";
import Contact from "../pages/contact";
import Header from "../components/Header/Header";
import { motion, AnimatePresence } from "framer-motion";
import useScrollBlock from "../hooks/useScrollBlock";
import * as ReactTranslated from "react-translated";
import ProjectsPage from "../pages/projects";
import Project_Zurich from "../pages/projects/project_zurich";
import Project_Trenes from "../pages/projects/project_trenes";
import Project_Aerolineas from "../pages/projects/project_aerolineas";
import Project_BDO from "../pages/projects/project_bdo";
import Project_Flip from "../pages/projects/project_flip";
import Project_Hicampus from "../pages/projects/project_hicampus";
import Project_Benomad from "../pages/projects/project_benomad";
import Project_Satoshi from "../pages/projects/project_satoshi";
import Project_Amadeus from "../pages/projects/project_amadeus";
import Project_Philipmorris from "../pages/projects/project_philipmorris";
import Project_Axion from "../pages/projects/project_axion";

function RoutesBeforeLocation() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/projects" component={ProjectsPage} />
        <Route exact path="/contact" component={Contact} />

        <Route exact path="/projects/zurich" component={Project_Zurich} />
        <Route exact path="/projects/trenesargentinos" component={Project_Trenes} />
        <Route exact path="/projects/aerolineas" component={Project_Aerolineas} />
        <Route exact path="/projects/bdo" component={Project_BDO} />
        <Route exact path="/projects/benomad" component={Project_Benomad} />
        <Route exact path="/projects/flip" component={Project_Flip} />
        <Route exact path="/projects/hicampus" component={Project_Hicampus} />
        <Route exact path="/projects/satoshi" component={Project_Satoshi} />
        <Route exact path="/projects/amadeus" component={Project_Amadeus} />
        <Route exact path="/projects/philipmorris" component={Project_Philipmorris} />
        <Route exact path="/projects/axion" component={Project_Axion} />

        <Route exact path="*" component={Home} />
      </Switch>
    </>
  );
}

export default function Routes() {
  const [open, setOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();

  const isOpen = () => {
    setOpen(true);
    blockScroll();
  };

  const closeMenu = () => {
    setOpen(false);
    allowScroll();
  };

  //lets start animation
  const item = {
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 0.5,
      },
    },
  };

  console.log(open);

  return (
    <Router>
      <div className="containerMenu" id="top">
        <ScrollToTop />
        <Header isOpen={isOpen} />
        <RoutesBeforeLocation />

        <AnimatePresence>
          {open && (
            <motion.div
              className="menu_container"
              variants={item}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "100vh", opacity: 1 }}
              transition={{ duration: 0.3 }}
              exit="exit"
            >
              <div className="header_menu open-navbar">
                {" "}
                <Header isOpen={isOpen} type="white" />
              </div>
              <div className="btn_close" onClick={() => closeMenu()}>
                X
              </div>

              <motion.a
                href="/projects"
                initial={{ y: 80, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.8 }}
                exit={{
                  opacity: 0,
                  y: 90,
                  transition: {
                    ease: "easeInOut",
                    delay: 0.4,
                  },
                }}
                className="linkNavHeader linkMenu"
              >
                <ReactTranslated.Translate text="menuOption1" />
              </motion.a>

              <motion.a
                href="/about"
                initial={{ y: 80, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6 }}
                exit={{
                  opacity: 0,
                  y: 90,
                  transition: {
                    ease: "easeInOut",
                    delay: 0.3,
                  },
                }}
                className="linkNavHeader linkMenu"
              >
                <ReactTranslated.Translate text="menuOption2" />
              </motion.a>

              <motion.a
                href="/team"
                initial={{ y: 80, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                exit={{
                  opacity: 0,
                  y: 90,
                  transition: {
                    ease: "easeInOut",
                    delay: 0.2,
                  },
                }}
                className="linkNavHeader linkMenu"
              >
                <ReactTranslated.Translate text="menuOption3" />
              </motion.a>

              <motion.a
                href="/contact"
                initial={{ y: 80, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                exit={{
                  opacity: 0,
                  y: 90,
                  transition: {
                    ease: "easeInOut",
                    delay: 0.1,
                  },
                }}
                className="linkNavHeader linkMenu"
              >
                <ReactTranslated.Translate text="menuOption4" />
              </motion.a>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Router>
  );
}
