import Header from "../components/Header/Header";
import Brands from "../components/Home/Brands/Brands";
import Projects from "../components/Home/Projects/Projects";
import Slider from "../components/Home/Slider/Slider";
import Footer from "../components/Footer/Footer";
import ProjectsSection from "../components/Projects/ProjectsSection";

const ProjectsPage = () => {
  return (
    <>
      <ProjectsSection />

      <Footer />
    </>
  );
};

export default ProjectsPage;
