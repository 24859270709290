import Carousel from "react-bootstrap/Carousel";
import videoSlider from "../../../assets/slide/resumen.mp4";
import videoSlider_es from "../../../assets/slide/resumen_es.mp4";
import { useContext, useEffect, useRef, useState } from "react";
import Loading from "../../Loading/Loading";
import { Modal } from "react-bootstrap";
import { UserProvider } from "../../../router/AppProvider";

const Slider = () => {
  const [loading, setLoading] = useState(true);
  const [loading_es, setLoading_es] = useState(true);
  const videoEl = useRef(null);
  const videoEl_es = useRef(null);
  const { language } = useContext(UserProvider);

  const [modalShow, setModalShow] = useState(false);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  const attemptPlay_es = () => {
    videoEl_es &&
      videoEl_es.current &&
      videoEl_es.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
    attemptPlay_es();
  }, [language]);

  const onLoadedData = () => {
    setLoading(false);
  };

  const onLoadedData_es = () => {
    setLoading_es(false);
  };

  return (
    <>
      <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          {/* <img className="d-block w-100" src={imgSlider} alt="First slide" /> */}
          <div className="text-center" onClick={() => setModalShow(true)} style={{ cursor: "pointer" }}>
            {language === "en" ? (
              <>
                {loading && (
                  <div className="pt-5 pb-5">
                    <Loading />
                  </div>
                )}
                <video
                  style={{ maxWidth: "100%", margin: "0 auto" }}
                  playsInline
                  loop
                  muted
                  alt="WOP"
                  src={videoSlider}
                  ref={videoEl}
                  onLoadEnd={onLoadedData}
                  onLoadedData={onLoadedData}
                ></video>
              </>
            ) : (
              <>
                {loading_es && (
                  <div className="pt-5 pb-5">
                    <Loading />
                  </div>
                )}
                <video
                  style={{ maxWidth: "100%", margin: "0 auto" }}
                  playsInline
                  loop
                  muted
                  alt="WOP"
                  src={videoSlider_es}
                  ref={videoEl}
                  onLoadEnd={onLoadedData_es}
                  onLoadedData={onLoadedData_es}
                ></video>
              </>
            )}
          </div>
        </Carousel.Item>
      </Carousel>
      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-video-portada"
    >
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body className="p-0 m-0">
        <iframe
          width="100%"
          height="700"
          src="https://www.youtube.com/embed/ne74GHwsg7c"
          title="Wop communication agency"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal.Body>
    </Modal>
  );
}

export default Slider;
