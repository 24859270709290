import { Col, Container, Row } from "react-bootstrap";
import slider from "../../assets/projects/2024/zurich/slider.png";
import img01 from "../../assets/projects/2024/zurich/01.png";
import img02 from "../../assets/projects/2024/zurich/02.png";
import video from "../../assets/projects/2024/zurich/Videos/01.mp4";
import { useEffect, useRef } from "react";

import ReactTranslated from "react-translated";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const Project_Zurich = () => {
  const title = "Zurich";

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <Container className="container_projects p-0 m-0">
        <Row className="pb-md-5 pb-4">
          <Col>
            <img src={slider} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={12} xs={11} className="mx-auto">
            <p className="tituloProject">
              <ReactTranslated.Translate text="zurich1" />
            </p>
            <p className="subtituloProject">
              {" "}
              <ReactTranslated.Translate text="zurich2" />
            </p>
          </Col>
        </Row>
        <Row className="pb-md-5 pb-4">
          <Col>
            <img src={img01} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={0} className="mx-auto"></Col>
          <Col md={6} xs={11} className="mx-auto">
            <p>
              <ReactTranslated.Translate text="zurich3" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            <img src={img02} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={0} className="mx-auto"></Col>
          <Col md={6} xs={11} className="mx-auto">
            <p>
              <ReactTranslated.Translate text="zurich4" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col>
            {" "}
            <div className="text-center ">
              <video
                style={{ maxWidth: "100%", margin: "0 auto" }}
                playsInline
                loop
                muted
                alt="WOP"
                src={video}
                ref={videoEl}
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={4} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject241" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              {" "}
              <ReactTranslated.Translate text="zurich5" />
            </p>
          </Col>
          <Col md={4} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject242" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="zurich6" />
            </p>
          </Col>
          <Col md={4} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject243" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="zurich7" />
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between pt-md-5 pb-md-5 pt-4 pb-4 pe-md-5 pe-2">
            <Link to="/projects/axion" className="linkNext">
              <span>{"<<"}</span> <ReactTranslated.Translate text="linkback" />
            </Link>
            <Link to="/projects/trenesargentinos" className="linkNext rightN">
              <ReactTranslated.Translate text="linknext" /> >>
            </Link>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Project_Zurich;
