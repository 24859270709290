import { Col, Container, Row } from "react-bootstrap";
import slider from "../../assets/projects/2024/axion/slider.png";
import img01 from "../../assets/projects/2024/axion/01.png";
import img02 from "../../assets/projects/2024/axion/02.png";
import { useEffect, useRef } from "react";

import ReactTranslated from "react-translated";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const Project_Axion = () => {
  const title = "Axion";

  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <>
      <Container className="container_projects p-0 m-0">
        <Row className="pb-md-5 pb-4">
          <Col>
            <img src={slider} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-0 pt-4 pb-4">
          <Col md={12} xs={11} className="mx-auto">
            <p className="lightTxt txt26">
              <ReactTranslated.Translate text="axion1" />
            </p>
            <p className=" txt26" style={{ fontWeight: "bold" }}>
              <ReactTranslated.Translate text="axion2" />
            </p>
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-5">
          <Col md={6} xs={11} className="mx-auto">
            <p className="lightTxt">
              <ReactTranslated.Translate text="axion3" />
            </p>
          </Col>
          <Col md={6} xs={11} className="mx-auto">
            <p className="lightTxt">
              <ReactTranslated.Translate text="axion4" />
            </p>
          </Col>
        </Row>
        <Row className="pb-md-5 pb-4">
          <Col>
            <img src={img01} alt={title} />
          </Col>
        </Row>
        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={6} xs={0} className="mx-auto" />
          <Col md={6} xs={11} className="mx-auto">
            <p className="lightTxt">
              <ReactTranslated.Translate text="axion5" />
            </p>
          </Col>
        </Row>

        <Row className="pb-md-5 pb-4 pt-4">
          <Col>
            <img src={img02} alt={title} />
          </Col>
        </Row>

        <Row className="pt-md-5 pb-md-5 pt-4 pb-4">
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject242" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              <ReactTranslated.Translate text="axion6" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject243" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>
              {" "}
              <ReactTranslated.Translate text="axion7" />
            </p>
          </Col>
          <Col md={3} xs={11} className="pt-md-0 pb-md-0 pt-4 pb-4 mx-auto">
            <p style={{ whiteSpace: "pre-line", fontWeight: "bold" }} className="pb-4">
              {" "}
              <ReactTranslated.Translate text="titleproject244" />
            </p>
            <p style={{ whiteSpace: "pre-line", lineHeight: "16px" }}>2023</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between pt-md-5 pb-md-5 pt-4 pb-4 pe-md-5 pe-2">
            <Link to="/projects/philipmorris" className="linkNext">
              <span>{"<<"}</span> <ReactTranslated.Translate text="linkback" />
            </Link>

            <Link to="/projects/zurich" className="linkNext">
              <ReactTranslated.Translate text="linknext" /> >>
            </Link>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Project_Axion;
