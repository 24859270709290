import { Col, Container, Row } from "react-bootstrap";
import projectsTitle from "../../assets/projects_title.png";
import projectsTitle_es from "../../assets/projects_title_es.png";
import { useContext } from "react";
import { UserProvider } from "../../router/AppProvider";
import Project01 from "../../assets/projects/2024/home/Project01.png";
import Project01_hover from "../../assets/projects/2024/home/Project01-hover.png";
import Project01_mobile from "../../assets/projects/2024/home/mobile/Project01.png";

import Project02 from "../../assets/projects/2024/home/Project02.png";
import Project02_hover from "../../assets/projects/2024/home/Project02-hover.png";
import Project02_mobile from "../../assets/projects/2024/home/mobile/Project02.png";

import Project03 from "../../assets/projects/2024/home/Project03.png";
import Project03_hover from "../../assets/projects/2024/home/Project03-hover.png";
import Project03_mobile from "../../assets/projects/2024/home/mobile/Project03.png";

import Project04 from "../../assets/projects/2024/home/Project04.png";
import Project04_hover from "../../assets/projects/2024/home/Project04-hover.png";
import Project04_mobile from "../../assets/projects/2024/home/mobile/Project04.png";

import Project05 from "../../assets/projects/2024/home/Project05.png";
import Project05_hover from "../../assets/projects/2024/home/Project05-hover.png";
import Project05_mobile from "../../assets/projects/2024/home/mobile/Project05.png";

import Project06 from "../../assets/projects/2024/home/Project06.png";
import Project06_hover from "../../assets/projects/2024/home/Project06-hover.png";
import Project06_mobile from "../../assets/projects/2024/home/mobile/Project06.png";

import Project07 from "../../assets/projects/2024/home/Project07.png";
import Project07_hover from "../../assets/projects/2024/home/Project07-hover.png";
import Project07_mobile from "../../assets/projects/2024/home/mobile/Project07.png";

import Project08 from "../../assets/projects/2024/home/Project08.png";
import Project08_hover from "../../assets/projects/2024/home/Project08-hover.png";
import Project08_mobile from "../../assets/projects/2024/home/mobile/Project08.png";

import Project09 from "../../assets/projects/2024/home/Project09.png";
import Project09_hover from "../../assets/projects/2024/home/Project09-hover.png";
import Project09_mobile from "../../assets/projects/2024/home/mobile/Project09.png";

import Project10 from "../../assets/projects/2024/home/Project10.png";
import Project10_hover from "../../assets/projects/2024/home/Project10-hover.png";
import Project10_mobile from "../../assets/projects/2024/home/mobile/Project10.png";

import Project11 from "../../assets/projects/2024/home/Project11.png";
import Project11_hover from "../../assets/projects/2024/home/Project11-hover.png";
import Project11_mobile from "../../assets/projects/2024/home/mobile/Project11.png";
import { Link } from "react-router-dom";

const ProjectsSection = () => {
  const { language } = useContext(UserProvider);

  const projects = [
    { img: Project01, hoverImg: Project01_hover, mobile: Project01_mobile, link: "zurich" },
    { img: Project02, hoverImg: Project02_hover, mobile: Project02_mobile, link: "trenesargentinos" },
    { img: Project03, hoverImg: Project03_hover, mobile: Project03_mobile, link: "aerolineas" },
    { img: Project04, hoverImg: Project04_hover, mobile: Project04_mobile, link: "bdo" },
    { img: Project05, hoverImg: Project05_hover, mobile: Project05_mobile, link: "benomad" },
    { img: Project06, hoverImg: Project06_hover, mobile: Project06_mobile, link: "flip" },
    { img: Project07, hoverImg: Project07_hover, mobile: Project07_mobile, link: "hicampus" },
    { img: Project08, hoverImg: Project08_hover, mobile: Project08_mobile, link: "satoshi" },
    { img: Project09, hoverImg: Project09_hover, mobile: Project09_mobile, link: "amadeus" },
    { img: Project10, hoverImg: Project10_hover, mobile: Project10_mobile, link: "philipmorris" },
    { img: Project11, hoverImg: Project11_hover, mobile: Project11_mobile, link: "axion" },
  ];

  return (
    <Container fluid className="container_projects p-0 m-0" id="projects">
      <Container className="pb-5 mx-md-auto m-0 px-md-auto p-0">
        <Row className="pt-5 pb-5">
          <Col xxl={5} xs={11} className="mx-auto"></Col>
        </Row>
        <Row>
          <Col xxl={6} xs={11} className="mx-auto pb-5">
            {language === "en" ? (
              <img src={projectsTitle} alt="WOP" className="img-fluid" />
            ) : (
              <img src={projectsTitle_es} alt="WOP" className="img-fluid" />
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} className="mx-auto">
            <Row className="d-md-flex  d-none">
              {projects.map((project, index) => (
                <Col xs={12} sm={6} key={index}>
                  {" "}
                  {/* Adjusted for smaller screens */}
                  <div className="image-container" style={{ position: "relative" }}>
                    <Link to={`projects/${project.link}`}>
                      <img src={project.img} alt={`Project ${index + 1}`} className="project-image d-md-block d-none" />
                      <img src={project.mobile} alt={`Project ${index + 1} Hover`} className="hover-image" />
                    </Link>
                  </div>
                </Col>
              ))}
            </Row>

            <Row className="d-md-none d-block">
              {projects.map((project, index) => (
                <Col xs={12} sm={6} key={index}>
                  {" "}
                  {/* Adjusted for smaller screens */}
                  <div className="image-container" style={{ position: "relative" }}>
                    <Link to={`projects/${project.link}`}>
                      <img src={project.img} alt={`Project ${index + 1}`} className="project-image d-md-block d-none" />
                      <img src={project.hoverImg} alt={`Project ${index + 1} Hover`} className="hover-image" />
                      <img src={project.mobile} alt={`Project ${index + 1} Mobile`} className="mobile-image" />
                    </Link>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ProjectsSection;
