//eslint-disable
export default {
  // Static text
  menuOption1: {
    en: "Projects",
    es: "Proyectos",
  },
  menuOption2: {
    en: "About",
    es: "Nosotros",
  },
  menuOption3: {
    en: "Team & Partners",
    es: "Equipo",
  },
  menuOption4: {
    en: "Get in Touch",
    es: "Contacto",
  },
  fraseCreativeIntro: {
    en: "We are a creative and innovative communication agency.",
    es: "Somos una agencia de comunicación, creativa e innovadora.",
  },
  fraseCreativeIntro2: {
    en: "We were born out of a collaborative format which allows us to have high-level multidisciplinary teams for global projects.",
    es: "Nacimos con un formato colaborativo, lo que nos permite contar con equipos multidisciplinarios de alto nivel para proyectos globales.",
  },

  lookingForChallenges: {
    en: "Looking for challenges",
    es: "Buscando desafíos",
  },

  viewReel: {
    en: "View Full Reel >>",
    es: "Ver Reel Completo >>",
  },

  home: {
    en: "We are a creative and innovative communication agency. We were born out of a collaborative format which allows us to have high-level multidisciplinary teams for global projects.",
    es: "Somos una agencia de comunicación, creativa e innovadora. Nacimos con un formato colaborativo, lo que nos permite contar con equipos multidisciplinarios de alto nivel para proyectos globales.",
  },
  titleProjectBenomad: {
    en: "About Benomad",
    es: "Sobre Benomad",
  },
  contenidoProjectBenomad: {
    en: "Benomad is a startup that, through an app, allows users to choose different productive and inspiring work environments every day, adapting to each professional and company’s needs.",
    es: "Benomad es una startup que permite, a traves de una app, elegir diferentes ambientes de trabajo, productivos e inspiradores cada día, adaptandosé a cada necesidad de profesionales y empresas.",
  },
  descriptionBenomad: {
    en: "Naming, Branding & Visual Identity, Brand Applications, UX/UI Web & Mobile App (Android y iOS), Spot Production, Digital and print material, Campaigns & Events, Social Media",
    es: "Naming, Branding e Identidad, Aplicaciones de Marca, UX/UI Web & Mobile App (Android y iOS), Producción de Spot, Material digital e impreso, Campañas y Eventos, Redes Sociales.",
  },
  titleProjectFlip: {
    en: "About Flip",
    es: "Sobre Flip",
  },
  contenidoProjectFlip: {
    en: "Flip! is an innovative insurtech with a fresh and renewed approach to the insurance segment with a young and sustainable proposition. Our challenge was to help them find a name and identity that reflects the purpose of insurance for a better world.",
    es: "Flip! es una innovadora insurtech con un enfoque fresco y renovado para el segmento de seguros con una propuesta joven y sustentable. Nuestro desafío fue ayudarlos a encontrar un nombre e identidad que refleje el propósito de un seguro para un mundo mejor.",
  },
  descriptionFlip: {
    en: "Naming, Branding & Visual Identity, Brand Applications, UX/UI Web & Mobile, Digital Material, Icon Design, Infographics, Copywriting, Social Media.",
    es: "Naming, Branding e Identidad, Aplicaciones de Marca, UX/UI Web & Mobile, Material digital, Diseño de Íconos, Infografias, Copywriting, Redes Sociales.",
  },
  titleServicesNew2: {
    en: "Services",
    es: "Servicios",
  },
  titleProjectTrenesaArgentinos: {
    en: "About Trenes Argentinos",
    es: "Sobre Trenes Argentinos",
  },
  contenidoProjectTrenesArgentinos: {
    en: "Trenes Argentinos had the need to offer an app where its users can access information about all stations, combinations with subways, metrobus, bus lines or EcoBici, ticket office hours, Passenger Service Centers, bathrooms and WiFi connection areas.",
    es: "Trenes Argentinos tenía la necesidad de ofrecer una app donde sus usuarios dispongan de información sobre todas las estaciones, combinaciones con subtes, metrobus, líneas de colectivos o EcoBici, horario de las boleterías, Centros de Atención al Pasajero, baños y conexiones de WiFi.",
  },
  descriptionTrenes: {
    en: "Backend Web Development.",
    es: "Desarrollo Backend Web.",
  },
  titleProjectZurich: {
    en: "About Zurich Santander",
    es: "Sobre Zurich Santander",
  },
  contenidoProjectZurich: {
    en: "We work together with Zurich Santander to improve their identity and user experience. We provide daily comprehensive support to cover all their internal and external communication needs.",
    es: "Trabajamos junto a Zurich Santander para mejorar su identidad y experiencia con el usuario. Brindamos a diario un soporte integral para cubrir todas sus necesidades comunicacionales tanto internas como externas.",
  },
  descriptionZurich: {
    en: "Brand Applications, Digital and Printed Material, Copywriting, Web UX/UI Design, Development, E-mail marketing, Icon Design, Infographics, Illustrations, Motion Graphics.",
    es: "Aplicaciones de Marca, Material digital e impreso, Copywriting, Diseño UX/UI Web, Desarrollo, E-mail marketing, Diseño de Íconos, Infografías, Ilustraciones, Animación.",
  },
  titleBrandTrust: {
    en: "The brands that trust us",
    es: "Las marcas que confían en nosotros",
  },
  titlefooter: {
    en: "Footer",
    es: "Pie de página",
  },
  titleEmailUs: {
    en: "Email us",
    es: "Mandanos un email",
  },
  titlefindUs: {
    en: "Find us",
    es: "Encontranos en",
  },
  titleFollowUs: {
    en: "Follow us",
    es: "Seguinos en",
  },
  titleAbout: {
    en: "Who we are",
    es: "Quienes Somos",
  },
  contenidoWhoweare: {
    en: "We are a creative agency, growing and evolving since 2011.We are innovative and passionate, always looking for a new challenge.",
    es: "Somos una agencia creativa, creciendo y evolucionando desde 2011. Somos innovadores, apasionados y siempre en busca de nuevos desafíos.",
  },
  contenidoWhoweare2: {
    en: "Come and meet the dream team!",
    es: "¡Vení a conocer al equipo de ensueño!",
  },
  titleWhatwedo: {
    en: "What we do",
    es: "Qué hacemos",
  },
  contenidoWhatwedo: {
    en: "Brading, Communication, Strategy and Technology are our great pillars. We believe in the magic of communication, creativity and the ability  to reach thousands of people with the right message and channel. We like to create new paths, experiences and results along with our clients.",
    es: "Branding, Comunicación, Estrategia y Tecnología son nuestros grandes pilares. Creemos en la magia de la comunicación, de la creatividad y de cómo poder llegar a miles de personas con el mensaje y el canal correcto. Nos gusta crear junto a nuestros clientes nuevos caminos, experiencias y resultados.",
  },
  titleServices1: {
    en: "NAMING",
    es: "NAMING",
  },
  titleServices2: {
    en: "BRANDING",
    es: "BRANDING",
  },
  titleServices3: {
    en: "IDENTITY DESIGN",
    es: "DISEÑO DE IDENTIDAD",
  },
  titleServices4: {
    en: "UX / UI DESIGN",
    es: "DISEÑO UX / UI",
  },
  titleServices5: {
    en: "DEVELOPEMENT",
    es: "DESARROLLO WEB",
  },
  titleServices6: {
    en: "PRODUCTION",
    es: "PRODUCCIÓN",
  },
  titleServices6b: {
    en: "ANIMATION",
    es: "ANIMACIÓN",
  },
  titleServices7: {
    en: "MOTION",
    es: "ANIMACIÓN",
  },
  titleServices8: {
    en: "SEO",
    es: "SEO",
  },
  titleServices9: {
    en: "MEDIA",
    es: "MEDIA",
  },
  titleServices10: {
    en: "ANALYTICS",
    es: "ANALITICAS",
  },
  titleServices13: {
    en: "PAID MEDIA",
    es: "PAID MEDIA",
  },
  titleServices13b: {
    en: "CAMPAIGNS & EVENTS",
    es: "CAMPAÑAS Y EVENTOS",
  },
  titleServices11: {
    en: "PRESS",
    es: "PRENSA",
  },
  titleServices12: {
    en: "WHATEVER YOU MAY NEED",
    es: "LO QUE PUEDAS NECESITAR",
  },
  titleHowwedoit: {
    en: "How we do it",
    es: "Cómo lo hacemos",
  },
  contenidoHowwedoPresent: {
    en: "We present you our partners and friends.",
    es: "Les presentamos a nuestros socios y amigos.",
  },
  contenidoHowwedoit: {
    en: "We’re not alone, that’s our big secret. We have a great multidisciplinary and colaborative team that provides experts in all areas so as to work integratly with our clients, having a complete and global vision of each project and its goals.",
    es: "No estamos solos, ese es nuestro gran secreto. Contamos con un gran equipo multidisciplinar y colaborativo que aporta expertos en todas las áreas, para poder trabajar de forma integrada con nuestras marcas, teniendo una visión completa y global de los proyectos y sus objetivos. ",
  },
  titleTeamWopPartners: {
    en: "Team Wop & Partners",
    es: "Nuestro Equipo & Socios",
  },
  contenidoTeamWopPartners: {
    en: "Together we stand",
    es: "Hay equipo",
  },
  titleGetin: {
    en: "Get in",
    es: "Ponete en",
  },

  titleGetintouch: {
    en: "touch!",
    es: "contacto!",
  },
  contenidoGetintouch: {
    en: "Wanna contact us? We can schedule an online meeting or you can come and visit our office in Buenos Aires.",
    es: "¿Querés contactarte con nosotros? Podemos coordinar una videoconferencia o podes venir a visitar nuestras oficinas en Buenos Aires.",
  },
  titleRaise: {
    en: "Raise",
    es: "Elevá tu",
  },
  titleBrand: {
    en: "your brand",
    es: "marca",
  },
  contenidoRaise: {
    en: "We’re ready to take your project to the next level. Fill out the form, and let’s talk about how we can help your brand grow.",
    es: "Estamos listos para llevar su proyecto al siguiente nivel. Rellena el formulario y hablemos de cómo podemos ayudar a tu marca a crecer.",
  },
  chatWithUs: {
    en: "Chat with us",
    es: "Chateá con nosotros",
  },
  titleproject241: {
    en: "Technology",
    es: "Tecnología",
  },
  titleproject242: {
    en: "Team",
    es: "Equipo",
  },
  titleproject243: {
    en: "Target",
    es: "Target",
  },
  titleproject244: {
    en: "Launch",
    es: "Lanzamiento",
  },
  linkback: {
    en: "Previous project",
    es: "Proyecto anterior",
  },
  linknext: {
    en: "Next project",
    es: "Siguiente proyecto",
  },
  vermasproyectos: {
    en: "See more projects",
    es: "Ver más proyectos",
  },
  zurich1: {
    en: "Zurich Santander Argentina",
    es: "Zurich Santander Argentina",
  },
  zurich2: {
    en: "Transforming the experience",
    es: "Transformando la Experiencia ",
  },
  zurich3: {
    en: "Zurich Santander, a leader in insurance, was looking for a comprehensive improvement in its user experience and brand perception. This involved the intervention in different pieces, from legal documents and internal communication pieces, to the design and development of their new website.",
    es: "Zurich Santander, líder en seguros, buscaba una mejora integral en su experiencia de usuario y en la percepción de su marca. Esto implicó la intervención en diferentes piezas, desde documentos legales y piezas de comunicación interna, hasta el diseño y desarrollo de su nuevo sitio web.",
  },
  zurich4: {
    en: "Since 2016, we have provided comprehensive support for their communicative needs, both internal and external. We introduced a new corporate identity with illustrations, making communications more accessible and friendly. Working closely with their marketing team, we defined strategies and timelines for each project.",
    es: "Desde el 2016, hemos proporcionado un soporte integral para sus necesidades comunicativas, tanto internas como externas. Introducimos una nueva identidad empresarial con ilustraciones, haciendo que las comunicaciones sean más accesibles y amigables. Trabajamos mano a mano con su equipo de marketing, definiendo estrategias y plazos para cada proyecto.",
  },
  zurich5: {
    en: "· Web: Web Sphere portal express (IBM)\n\n· Web 2: Frontend React JS / Backend Strapi\n\n· HTML\n\n· Moho + Adobe After Effects",
    es: "· Web: Web Sphere portal express (IBM) \n\n · Web 2: Frontend React JS / Backend Strapi \n\n· HTML\n\n· Moho + Adobe After Effects",
  },
  zurich6: {
    en: "· PM sr\n\n· Full Stack Sr.\n\n· Fronte nd .Net Sr\n\n· Designer Sr\n\n· Designer Ssr\n\n· Designer and illustrator\n\n· Animator Ssr",
    es: "· PM sr\n\n· Full Stack Sr.\n\n· Fronte nd .Net Sr\n\n· Diseñador Sr\n\n· Diseñador Ssr\n\n· Diseñador e ilustrador\n\n· Animador Ssr",
  },
  zurich7: {
    en: "Clients and company employees",
    es: "Clientes y empleados de la compañia",
  },

  trenes3: {
    en: "The company raised the need to enhance its processes and technology to provide a more efficient and satisfactory experience for over 1.5 million people who depend on train services daily.",
    es: "La empresa planteó la necesidad de mejorar sus procesos y tecnología para brindar una experiencia más eficiente y satisfactoria para las más de 1.5 millones de personas que dependen diariamente de los servicios de trenes.",
  },
  trenes4: {
    en: "We collaborated with the IT and Marketing teams to create a new app with a refreshed user experience, integrating all client-side services to provide them with new functionalities.",
    es: "Trabajamos junto con el equipo de IT y Marketing para lograr una nueva app con renovada experiencia de usuario, e integrando todos los servicios del lado del cliente para dotarlos de nuevas funcionalidades.",
  },
  trenes5: {
    en: "· React Native\n\n· iOS and Android APP.",
    es: "· React Native\n\n· APP iOS y Android.",
  },
  trenes6: {
    en: "· PM Sr\n\n· UX/UI Designer Ssr\n\n· Frontend Developer Sr\n\n· Frontend Developer Ssr\n\n· Analyst Jr ",
    es: "· PM Sr\n\n· Diseñador UX/UI Ssr\n\n· Frontend Developer Sr\n\n· Frontend Developer Ssr.\n\n· Analista Jr",
  },
  trenes7: {
    en: "End Client B2C ",
    es: "Cliente Final B2C",
  },
  trenes8: {
    en: "March 2023",
    es: "Marzo 2023",
  },
  aerolineas1: {
    en: "Aerolíneas Argentinas",
    es: "Aerolíneas Argentinas",
  },
  aerolineas2: {
    en: "An app that makes you fly",
    es: "Una app que te hace volar",
  },
  aerolineas3: {
    en: "Aerolíneas needed a mobile tool for quick access, enabling its employees to report incidents easily, swiftly, and clearly in various work settings, including operational and commercial offices, operational areas, boarding, runway, and even on the aircraft itself.",
    es: "Aerolíneas necesitaba una herramienta mobile de rápido acceso para que sus empleados pudiesen realizar reportes en forma fácil, ágil y clara en relación a todo tipo de incidentes que suelen presentarse, tanto en una oficina operativa- comercial, como en áreas de operaciones, embarque, pista o en el mismo avión.",
  },
  aerolineas4: {
    en: "We proposed the development of an App for employees, thus facilitating the execution of reports quickly and easily, through a photo, video, text or audio. The app was developed for Android, iOS and Windows.",
    es: "Planteamos el desarrollo de una App para empleados, facilitando de este modo la ejecución de reportes de forma rápida y sencilla, a través de una foto, un video, un texto o un audio. La app se desarrolló para Android, iOS y Windows.",
  },
  aerolineas5: {
    en: "In order to achieve engagement with the teams and encourage the proper use of the application, explanatory audiovisual material for internal use was generated. More than 50% of the company's 12,000 employees participated, achieving more than 50% of active users, well above the target set by the company.",
    es: "Para lograr engagement con los equipos y favorecer el buen uso de la aplicación, se generó material audiovisual explicativo de uso interno. Participan más del 50% de los 12.000 empleados de la firma, logrando más del 50% de usuarios activos, muy por encima del objetivo planteado por la compañía.",
  },
  aerolineas6: {
    en: "· Ionic - .Net 2016 - 2023\n\n· React Native 2023\n\n· iOS Android",
    es: "· Ionic - .Net 2016 - 2023\n\n· React Native 2023\n\n· iOS Android",
  },
  aerolineas7: {
    en: "· PM Sr\n\n· Full Stack Sr.\n\n· Frontend .Net Sr\n\n· Designer Sr",
    es: "· PM Sr\n\n· Full Stack Sr.\n\n· Frontend .Net Sr\n\n· Diseñador Ssr",
  },
  aerolineas8: {
    en: "Company employees",
    es: "Empleados de la compañía",
  },
  bdo1: {
    en: "BDO (Ethic Compass) ",
    es: "BDO (Ethic Compass)",
  },
  bdo2: {
    en: "Guiding Corporate Integrity",
    es: "Guiando la integridad Empresarial",
  },
  bdo3: {
    en: "BDO, a leading financial consulting firm, identified the growing need to address Compliance issues in large companies. We collaborated to create a tool that allowed their clients to anonymously report unethical practices.",
    es: "BDO, una empresa líder en consultoría financiera, detectó la creciente necesidad de abordar temas de Compliance en grandes compañías. Trabajamos juntos para crear una herramienta que permitiera a sus clientes informar de manera anónima sobre prácticas no éticas.",
  },
  bdo4: {
    en: "We developed an app with interactive and engaging features to make the content on company policies and best practices more accessible and appealing. The application also enables the secure and anonymous submission of reports through audio, photos, or videos, simplifying the process. We also developed all the graphic and audiovisual material for the internal communication of the new tool.",
    es: "Desarrollamos una App con características interactivas y divertidas para hacer más accesible y atractivo el contenido sobre políticas y buenas prácticas de la empresa. La aplicación también permite la presentación anónima y segura de informes a través de audio, fotos o videos, simplificando el proceso. Además desarrollamos todo el material grafico y audiovisual para la comunicación interna de la nueva herramienta.",
  },
  bdo5: {
    en: "· App React Native\n\n· iOS Android",
    es: "· App React Native\n\n· iOS Android",
  },
  bdo6: {
    en: "· PM Sr\n\n· Full Stack Sr.\n\n· Frontend .Net Sr\n\n· Designer Ssr",
    es: "· PM Sr\n\n· Full Stack Sr.\n\n· Frontend .Net Sr\n\n· Diseñador Ssr",
  },
  bdo7: {
    en: "BDO B2B Clients",
    es: "Clientes B2B de BDO",
  },
  flip1: {
    en: "Insurance for a Better World",
    es: "Seguros para un mundo mejor",
  },
  flip2: {
    en: "In the midst of the insurtech boom, Flip! emerges with an innovative and sustainable perspective. They challenged us to create a name and identity that conveys their purpose of 'insurance for a better world' and to bring it to a user-friendly platform.",
    es: "En el auge de las insurtech, surge Flip! con una perspectiva innovadora y sostenible. Nos desafiaron a crear un nombre e identidad que transmitiera su propósito de 'seguros para un mundo mejor' y a llevarlo a una plataforma fácil de usar.",
  },
  flip3: {
    en: "We undertook a comprehensive creative process, from the name and unique visual identity, to the development of two web platforms. The first one, the insurance platform, where you can learn about, quote and purchase your insurance online.  The second, the Flip! Store, offers sustainable products with special benefits for Flip! customers, but open to all interested parties.",
    es: "Realizamos un proceso creativo integral, desde el nombre y la identidad visual única, hasta el desarrollo de dos plataformas web. La primera, la plataforma de seguros, donde puedes conocer, cotizar y contratar tu seguro online. La segunda, la Tienda Flip!, ofrece productos sostenibles con beneficios especiales para clientes Flip!, pero abiertos a todos los interesados.",
  },
  flip4: {
    en: "· React Native",
    es: "· React Native",
  },
  flip5: {
    en: "· PM sr\n\n· Full Stack Sr.\n\n· Frontend .Net Sr\n\n· Designer Sr\n\n· Designer Ssr",
    es: "· PM sr\n\n· Full Stack Sr.\n\n· Frontend .Net Sr\n\n· Diseñador Sr· Diseñador Ssr",
  },
  flip6: {
    en: "Customers and employees of the company",
    es: "Clientes y empleados de la compañia",
  },
  hicampus1: {
    en: "Welcome to ",
    es: "Bienvenido a ",
  },
  hicampus2: {
    en: "HiCampus was introduced as a groundbreaking concept in hospitality—an American university campus-style space with modern amenities aimed at providing housing solutions for young students in the city of La Plata. To showcase their spaces, features, and unique experiences, HiCampus needed both a website and an app. ",
    es: "HiCampus se presentó como un nuevo concepto en “hospitality”. Es un espacio al estilo campus universitario americano con comodidades modernas, que busca ser una solución habitacional para jóvenes estudiantes en la ciudad de La Plata.  Necesitaban una web y una App que destacaran sus espacios, sus características y las experiencias que ofrecen.",
  },
  hicampus3: {
    en: "We designed the brand, website, and HiCampus app using Adobe XD, with a focus on creating inclusive and youthful communication tailored to the users' needs. The result is a comprehensive suite, ideal for flexible and hybrid stays.",
    es: "Diseñamos la marca, la web y la App HiCampus en Adobe XD. Nos enfocamos en crear una comunicación inclusiva y juvenil, pensando en las necesidades de los usuarios.  El resultado: una suite completa, perfecta para estancias flexibles e híbridas.",
  },
  hicampus4: {
    en: "- App: React Native + SDK Expo (for iOS and Android).\n\n- Web Backend: PHP Laravel + MySql\n\n- Web: CMS Wordpress + PHP Laravel + MySql",
    es: "· App: React Native + SDK Expo (para iOS y Android)\n\n· Backend Web: PHP Laravel + MySql\n\n· Web: CMS Wordpress + PHP Laravel + MySql",
  },
  hicampus5: {
    en: "- Sr PM\n\n- Designer Sr\n\n- Dev Mobile Sr\n\n- Dev Backend PHP Sr\n\n- Dev Frontend Sr (Wordpress + HTML)",
    es: "· PM Sr\n\n· Diseñador Sr\n\n· Dev Mobile Sr\n\n· Dev Backend PHP Sr\n\n· Dev Frontend Sr (Wordpress + HTML)",
  },
  hicampus6: {
    en: "B2C End Customer",
    es: "Cliente Final B2C",
  },
  benomad1: {
    es: "Work donde quieras!",
    en: "Work from anywhere",
  },
  benomad2: {
    en: "In a world increasingly focused on working from anywhere, Benomad emerges as a response to the needs of digital nomads. The flexibility to choose from a variety of coworking spaces, bars, restaurants, lounges and hotels, all with high-speed internet access and exclusive benefits for members of the Benomad community.",
    es: "En un mundo cada vez más enfocado en trabajar desde cualquier lugar, Benomad surge como una respuesta a las necesidades de los nómadas digitales. La flexibilidad de elegir entre diversos espacios de coworking, bares, restaurantes, salas y hoteles, todos con acceso a internet de alta velocidad y beneficios exclusivos para los miembros de la comunidad Benomad.",
  },
  benomad3: {
    en: "We built the Benomad concept from scratch: the name and visual identity, the advertising campaign, the web development and the App ensuring users a straightforward and accessible experience.",
    es: "Construimos el concepto Benomad desde cero: el nombre y la imagen visual, la campaña publicitaria, el desarrollo web y la App que garantiza a los usuarios una experiencia sencilla y accesible.",
  },
  benomad4: {
    en: "· React Native\n\n· iOS and Android APP.",
    es: "· React Native\n\n· APP iOS y Android.",
  },
  benomad5: {
    en: "· PM Sr\n\n· UX/UI Designer Ssr\n\n· Frontend Developer Sr\n\n· Frontend Developer Sr.\n\n· Jr Analyst",
    es: "· PM Sr\n\n· Diseñador UX/UI Ssr\n\n· Frontend Developer Sr\n\n· Frontend Developer Ssr.\n\n· Analista Jr",
  },
  benomad6: {
    en: "End Customer B2C/B2B",
    es: "Cliente Final B2C/B2B",
  },
  satoshi1: {
    en: "Cryptomice yourself with ",
    es: "¡Criptomízate con ",
  },
  satoshi2: {
    en: "Satoshi Tango, a leader in the crypto world, chose us to launch their new Visa card. We drew inspiration from turning cryptocurrencies into something tangible and part of daily life, giving birth to our concept 'Cryptomice your life.' Simply and directly, we bring the crypto universe into reality, connecting it with everyday situations through creative visual pieces.",
    es: "Satoshi Tango, líder en el mundo cripto, nos eligió para lanzar su nueva tarjeta Visa. Nos inspiramos en convertir las criptomonedas en algo tangible y cotidiano, así nació nuestro concepto: 'Criptomizá tu vida'. De manera simple y directa, llevamos el universo cripto a la realidad, conectándolo con situaciones de la vida diaria a través de creativas piezas visuales. ",
  },
  satoshi3: {
    en: "We were responsible for the creative concept and art direction, graphic and audiovisual production, as well as the planning and execution of the media campaign.",
    es: "Nos inspiramos en convertir las criptomonedas en algo tangible y cotidiano, así nació nuestro concepto: 'Criptomizá tu vida'. De manera simple y directa, llevamos el universo cripto a la realidad, conectándolo con situaciones de la vida diaria a través de creativas piezas visuales.",
  },
  satoshi4: {
    en: "· PM sr\n\n· Art Director\n\n· Sr Designer\n\n· Creative Sr\n\n· Media Planner",
    es: "· PM sr\n\n· Director de Arte\n\n· Diseñador Sr\n\n. Creativo Sr\n\n. Planificador de medios",
  },
  satoshi5: {
    en: "End Consumer B2C",
    es: "Cliente Final B2C",
  },
  amadeus1: {
    en: "Discover the World of ",
    es: "Descubre el Mundo de ",
  },
  amadeus001: {
    en: "Amadeus ",
    es: "Amadeus ",
  },
  amadeus2: {
    en: "in a fun Way",
    es: "de Manera Divertida",
  },
  amadeus3: {
    en: "Despite being a global leader in technological services for tourism, many travel agencies in Latin America and Europe using Amadeus services were not sufficiently familiar with the company.",
    es: "Aunque Amadeus es líder mundial en servicios tecnológicos para el turismo, muchas agencias de viaje en América Latina y Europa que utilizan sus servicios no conocen lo suficiente sobre la empresa. ",
  },
  amadeus4: {
    en: "We created Amadeus Kingdom, a trivia game that explored the history of the company and its services, such as flights, cars and hotels. ",
    es: "Creamos Amadeus Kingdom, un juego de trivia que exploraba la historia de la compañía y sus servicios, como vuelos, autos y hoteles. ",
  },
  amadeus5: {
    en: "Being a B2B business, we focused on specific communication actions for the travel industry and used Amadeus channels. We offered significant prizes, such as trips to the headquarters in Nice, France. ",
    es: "Al ser un negocio B2B, nos enfocamos en acciones de comunicación específicas para el sector turístico y utilizamos los canales de Amadeus. Ofrecimos premios significativos, como viajes a la sede central en Niza, Francia. ",
  },
  amadeus6: {
    en: "The application achieved high participation from more than 40,000 agencies across the Americas, with translations into Portuguese, English and Spanish. ",
    es: "La aplicación logró una alta participación de más de 40,000 agencias en toda América, con traducciones al portugués, inglés y español. ",
  },
  amadeus7: {
    en: "We actively engaged the global team and participated in the development of the product's branding, communication strategy, and management of specialized media and social networks throughout the Americas.",
    es: "Comprometimos activamente al equipo global y participamos en el desarrollo de la marca del producto, estrategia de comunicación, y gestión de medios especializados y redes sociales en toda América.",
  },
  amadeus8: {
    en: "B2B clients of the company (Travel agencies).",
    es: "Clientes B2B de la compañía (Agencias de turismo)",
  },
  amadeus9: {
    en: "· PM Sr\n\n· Dev Frontend iOS Sr\n\n· Dev Frontend Android Sr\n\n· DEv Backend Larabel Sr\n\n· FullStack Sr\n\n· Account Executive\n\n· Creative\n\n· Designer Sr\n\n· CopyWriter",
    es: "· PM Sr\n\n· Dev Frontend iOS Sr\n\n· Dev Frontend Android Sr\n\n· DEv Backend Larabel Sr\n\n· FullStack Ssr\n\n· Ejecutivo de cuenta\n\n· Creativo\n\n· Diseñador Sr\n\n· CopyWriter",
  },
  philip1: {
    en: "Philip Morris",
    es: "Philip Morris",
  },
  philip2: {
    en: "Gamifies Learning with PMIConnect",
    es: "Gamifica el aprendizaje con PMIConnect",
  },
  philip3: {
    en: "Philip Morris was looking for an upgrade in their training platforms, especially for induction programs aimed at young professionals and new employees. They needed something more engaging and compelling.",
    es: "Philip Morris buscaba una actualización en sus plataformas de capacitación, especialmente para los programas de inducción dirigidos a jóvenes profesionales y nuevos empleados. Necesitaban algo más atractivo y comprometedor.",
  },
  philip4: {
    en: "We created PMIConnect, a trivia-style game application that uses gamification to deliver content about different areas of the company. ",
    es: "Creamos PMIConnect, una aplicación de trivia estilo juego que utiliza la gamificación para brindar contenido sobre diferentes áreas de la empresa.",
  },
  philip5: {
    en: "We developed the creativity, implementation and communication strategy, as well as the content and technological development of the application. ",
    es: "Desarrollamos la creatividad, implementación y estrategia de comunicación, así como el contenido y desarrollo tecnológico de la aplicación. ",
  },
  philip6: {
    en: "Users, regardless of their position, could compete globally, generating weekly rankings with prizes. In addition, we created the game's  branding, communication videos, marketing pieces and banners. ",
    es: "Los usuarios, sin importar su puesto, podían competir globalmente, generando rankings semanales con premios. Además, creamos el branding del juego, videos de comunicación, piezas de marketing y banners. ",
  },
  philip7: {
    en: "Native Apps (iOS and Android) + Web app",
    es: "Apps nativas (iOS y Android) + Web app",
  },
  philip8: {
    en: "· PM Sr\n\n· Dev Frontend iOS Sr\n\n· Dev Frontend Android Sr\n\n· Dev Backend Larabel Sr\n\n· FullStack Sr\n\n· Account Executive\n\n· Creative\n\n· Designer Sr\n\n· CopyWriter",
    es: "· PM Sr\n\n· Dev Frontend iOS Sr\n\n· Dev Frontend Android Sr\n\n· Dev Backend Larabel Sr\n\n· FullStack Ssr\n\n· Ejecutivo de cuenta\n\n· Creativo\n\n· Diseñador Sr\n\n· CopyWriter",
  },
  philip9: {
    en: "Company employees",
    es: "Empleados de la compañia",
  },
  axion1: {
    en: "We're getting on board with ",
    es: "¡Nos subimos a ",
  },
  axion2: {
    en: "ON Axion!",
    es: "ON Axion!",
  },
  axion3: {
    en: "We implemented for Axion our customized software functional testing service to ensure quality and an excellent user experience in Axion's discount and benefits program App.",
    es: "Implementamos para Axion nuestro servicio personalizado de testing funcional de software para asegurar la calidad y una excelente experiencia de usuario en la App del programa de descuentos y beneficios de Axion.",
  },
  axion4: {
    en: "Our testing team focuses on identifying and eliminating software defects to ensure the product's correct and smooth functionality.",
    es: "Para ello, nuestro equipo de Testing se enfoca en identificar y eliminar defectos de software para garantizar una correcta y ágil funcionalidad del producto.",
  },
  axion5: {
    en: "We start the process with a detailed analysis of the requirements and objectives, verify that the software meets user expectations and work to achieve a smooth and error-free experience. We review the clarity of texts and the interface design, ensuring successful user interaction with the software. We provide ongoing support to ensure that the product remains reliable as it evolves and is updated.",
    es: "Comenzamos el proceso con un análisis detallado de sus requisitos y objetivos, verificamos que el software cumpla con las expectativas de los usuarios y trabajamos para lograr una experiencia  fluida y sin errores. Revisamos la claridad de los textos y el diseño de la interfaz y nos aseguramos de que la interacción de los usuarios con el software sea exitosa. Ofrecemos soporte continuo para garantizar que el producto siga siendo confiable a medida que evoluciona y se actualiza.",
  },
  axion6: {
    en: "· Consultant Sr\n\n· Tester Sr\n\n· Tester Jr",
    es: "· Consultor Sr\n\n· Tester Sr\n\n· Tester Jr",
  },
  axion7: {
    en: "End Consumer B2C",
    es: "Cliente Final B2C",
  },
};
