import { useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import imgSlider from "../../../assets/team/slider-team.png";
import imgSlider_es from "../../../assets/team/slider-team_es.png";
import { UserProvider } from "../../../router/AppProvider";

const SliderTeam = () => {
  const { language } = useContext(UserProvider);

  return (
    <Carousel className="pb-5" controls={false} indicators={false}>
      <Carousel.Item className="d-flex">
        {language === "en" ? (
          <img className="img-fluid" src={imgSlider} alt="First slide" />
        ) : (
          <img className="img-fluid" src={imgSlider_es} alt="First slide" />
        )}
      </Carousel.Item>
    </Carousel>
  );
};

export default SliderTeam;
