import { Col, Container, Row } from "react-bootstrap";

import brands_aerolineas from "../../../assets/brands/brands_aerolineas.png";
import brands_aeropuertos from "../../../assets/brands/brands_aeropuertos.png";
import brands_afi from "../../../assets/brands/brands_afi.png";
import brands_amadeus from "../../../assets/brands/brands_amadeus.png";
import brands_assist_med from "../../../assets/brands/brands_assist_med.png";
import brands_banelco from "../../../assets/brands/brands_banelco.png";
import brands_bdo from "../../../assets/brands/brands_bdo.png";
import brands_benomad from "../../../assets/brands/brands_benomad.png";
import brands_bodega from "../../../assets/brands/brands_bodega.png";
import brands_flip from "../../../assets/brands/brands_flip.png";
import brands_flor_de_cana from "../../../assets/brands/brands_flor_de_cana.png";
import brands_hausler from "../../../assets/brands/brands_hausler.png";
import brands_nxtp from "../../../assets/brands/brands_nxtp.png";
import brands_philip_morris from "../../../assets/brands/brands_philip_morris.png";
import brands_satoshi_tango from "../../../assets/brands/brands_satoshi_tango.png";
import brands_trenes from "../../../assets/brands/brands_trenes.png";
import brands_zs from "../../../assets/brands/brands_zs.png";
import brands_axion from "../../../assets/brands/axion.svg";
import * as ReactTranslated from "react-translated";
const brands = [
  { pos: 1, img: brands_philip_morris },
  { pos: 2, img: brands_zs },
  { pos: 3, img: brands_flip },
  { pos: 4, img: brands_nxtp },
  { pos: 5, img: brands_aeropuertos },
  { pos: 6, img: brands_bodega },
  { pos: 7, img: brands_benomad },
  { pos: 8, img: brands_satoshi_tango },
  { pos: 9, img: brands_aerolineas },
  { pos: 10, img: brands_banelco },
  { pos: 11, img: brands_assist_med },
  { pos: 12, img: brands_flor_de_cana },
  { pos: 13, img: brands_bdo },
  { pos: 14, img: brands_hausler },
  { pos: 15, img: brands_afi },
  { pos: 16, img: brands_trenes },
  { pos: 17, img: brands_amadeus },
  { pos: 18, img: brands_axion },
];

const Brands = () => {
  return (
    <Container fluid className="container_brands pt-5 pb-5">
      <Container>
        <Row>
          <Col>
            <h2 className="pt-5 pb-5">
              {" "}
              <ReactTranslated.Translate text="titleBrandTrust" />
            </h2>
          </Col>
        </Row>
        <Row>
          {brands
            .sort((a, b) => b.pos < a.pos)
            .map((b) => (
              <Col md={3} xs={6} className="mx-auto text-center">
                <img src={b.img} />
              </Col>
            ))}
        </Row>
      </Container>
    </Container>
  );
};

export default Brands;
