import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const ImageSwitcher = ({ project, link, language, imagePaths }) => {
  const [currentImageKey, setCurrentImageKey] = useState("a");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const cycleImages = ["a", "b", "c"]; // Define the cycle order
    let index = 0; // Start at the first image

    // Create an interval to cycle images
    const interval = setInterval(() => {
      if (!isHovered) {
        // Only cycle images if not hovered
        index = (index + 1) % cycleImages.length; // Move to the next image, wrap around
        setCurrentImageKey(cycleImages[index]);
      }
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [isHovered]); // Re-run effect if hovered state changes

  const handleMouseEnter = () => {
    setIsHovered(true); // Pause automatic cycling on hover
    setCurrentImageKey(currentImageKey + "2"); // Change to hovered image
  };

  const handleMouseOut = () => {
    setIsHovered(false); // Resume automatic cycling on mouse out
    setCurrentImageKey(currentImageKey.charAt(0)); // Return to base image
  };

  return (
    <Link to={link}>
      <img
        src={imagePaths[language][project][currentImageKey]}
        alt={`${project} display`}
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseOut}
        style={{ height: "auto", display: "block" }}
      />
    </Link>
  );
};

export default ImageSwitcher;
