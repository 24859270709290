import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import logoWhite from "../../assets/logo_white.png";
import iconMenu from "../../assets/menu.png";
import { UserProvider } from "../../router/AppProvider";

const Header = ({ isOpen, type }) => {
  const { changeLanguage, language } = useContext(UserProvider);

  return (
    <Navbar expand="lg" bg="dark" variant="light" className="w-100">
      <Container fluid={type === "white" ? true : false}>
        <Navbar.Brand>
          <Link to="/">
            <img src={type === "white" ? logoWhite : logo} alt="Wop" />
          </Link>
        </Navbar.Brand>

        <Navbar>
          <Nav className="me-auto"></Nav>
          <Nav className="d-flex justify-content-end align-items-center align-content-center">
            <Nav.Link onClick={() => changeLanguage("en")} className={`${language === "en" ? "lang-selected" : ""}`}>
              ENG
            </Nav.Link>
            <Nav.Link
              eventKey={2}
              onClick={() => changeLanguage("es")}
              className={`${language === "es" ? "lang-selected" : ""}`}
            >
              SPA
            </Nav.Link>
            <Nav.Link onClick={isOpen}>
              <img src={iconMenu} alt="Wop" />
            </Nav.Link>
          </Nav>
        </Navbar>
      </Container>
    </Navbar>
  );
};

export default Header;
