import { Accordion, Col, Container, Row } from "react-bootstrap";
import projectsTitle from "../../../assets/projects_title.png";
import projectsTitle_es from "../../../assets/projects_title_es.png";
import benomadProject from "../../../assets/projects/benomad.png";
import flipProject from "../../../assets/projects/flip.png";
import trenesProject from "../../../assets/projects/trenes.png";
import zurichProject from "../../../assets/projects/zurich.png";
import slideProject from "../../../assets/slide/slide_project.png";

//PICTURES FOR PROJECTS

//BENOMAD
import wop_projects_benomad01 from "../../../assets/projects/new/benomad/wop_projects_benomad01.png";
import wop_projects_benomad02 from "../../../assets/projects/new/benomad/wop_projects_benomad02.png";
import wop_projects_benomad03 from "../../../assets/projects/new/benomad/wop_projects_benomad03.png";
import wop_projects_benomad04 from "../../../assets/projects/new/benomad/wop_projects_benomad04.png";
import wop_projects_benomad05 from "../../../assets/projects/new/benomad/wop_projects_benomad05.png";
//FLIP
import wop_projects_flip01 from "../../../assets/projects/new/flip/wop_projects_flip01.png";
import wop_projects_flip02 from "../../../assets/projects/new/flip/wop_projects_flip02.png";
import wop_projects_flip03 from "../../../assets/projects/new/flip/wop_projects_flip03.png";
import wop_projects_flip04 from "../../../assets/projects/new/flip/wop_projects_flip04.png";
import wop_projects_flip05 from "../../../assets/projects/new/flip/wop_projects_flip05.png";
//TRENES
import wop_projects_trenes01 from "../../../assets/projects/new/trenes/wop_projects_trenes01.png";
import wop_projects_trenes02 from "../../../assets/projects/new/trenes/wop_projects_trenes02.png";
import wop_projects_trenes03 from "../../../assets/projects/new/trenes/wop_projects_trenes03.png";
import wop_projects_trenes04 from "../../../assets/projects/new/trenes/wop_projects_trenes04.png";
import wop_projects_trenes05 from "../../../assets/projects/new/trenes/wop_projects_trenes05.png";
//ZURICH
import wop_projects_zs01 from "../../../assets/projects/new/zurich/wop_projects_zs01.png";
import wop_projects_zs02 from "../../../assets/projects/new/zurich/wop_projects_zs02.png";
import wop_projects_zs03 from "../../../assets/projects/new/zurich/wop_projects_zs03.png";
import wop_projects_zs04 from "../../../assets/projects/new/zurich/wop_projects_zs04.png";
import wop_projects_zs05 from "../../../assets/projects/new/zurich/wop_projects_zs05.png";
import { useState } from "react";
import ProjectSlider from "../ProjectsSlider/ProjectSlider";
import * as ReactTranslated from "react-translated";
import { useEffect } from "react";
import { UserProvider } from "../../../router/AppProvider";
import { useContext } from "react";
import ContainerProject from "../ContainerProject/ContainerProject";
import { Link } from "react-router-dom";
// Importing images for project1 (Trenes Argentinos)
import en_home_project1_trenes_a from "../../../assets/projects/2024/home2/en/home_project1_trenes_a.png";
import en_home_project1_trenes_a2 from "../../../assets/projects/2024/home2/en/home_project1_trenes_a2.png";
import en_home_project1_trenes_b from "../../../assets/projects/2024/home2/en/home_project1_trenes_b.png";
import en_home_project1_trenes_b2 from "../../../assets/projects/2024/home2/en/home_project1_trenes_b2.png";
import en_home_project1_trenes_c from "../../../assets/projects/2024/home2/en/home_project1_trenes_c.png";
import en_home_project1_trenes_c2 from "../../../assets/projects/2024/home2/en/home_project1_trenes_c2.png";

// Importing images for project2 (CryptoMize Yourself with Satoshi Tango!)
import en_home_project2_satoshi_a from "../../../assets/projects/2024/home2/en/home_project2_satoshi_a.png";
import en_home_project2_satoshi_a2 from "../../../assets/projects/2024/home2/en/home_project2_satoshi_a2.png";
import en_home_project2_satoshi_b from "../../../assets/projects/2024/home2/en/home_project2_satoshi_b.png";
import en_home_project2_satoshi_b2 from "../../../assets/projects/2024/home2/en/home_project2_satoshi_b2.png";
import en_home_project2_satoshi_c from "../../../assets/projects/2024/home2/en/home_project2_satoshi_c.png";
import en_home_project2_satoshi_c2 from "../../../assets/projects/2024/home2/en/home_project2_satoshi_c2.png";

// Importing images for project3 (Zurich Santander Argentina)
import en_home_project3_zs_a from "../../../assets/projects/2024/home2/en/home_project3_zs_a.png";
import en_home_project3_zs_a2 from "../../../assets/projects/2024/home2/en/home_project3_zs_a2.png";
import en_home_project3_zs_b from "../../../assets/projects/2024/home2/en/home_project3_zs_b.png";
import en_home_project3_zs_b2 from "../../../assets/projects/2024/home2/en/home_project3_zs_b2.png";
import en_home_project3_zs_c from "../../../assets/projects/2024/home2/en/home_project3_zs_c.png";
import en_home_project3_zs_c2 from "../../../assets/projects/2024/home2/en/home_project3_zs_c2.png";

// Importing images for project4 (BeNomad)
import en_home_project4_benomad_a from "../../../assets/projects/2024/home2/en/home_project4_benomad_a.png";
import en_home_project4_benomad_a2 from "../../../assets/projects/2024/home2/en/home_project4_benomad_a2.png";
import en_home_project4_benomad_b from "../../../assets/projects/2024/home2/en/home_project4_benomad_b.png";
import en_home_project4_benomad_b2 from "../../../assets/projects/2024/home2/en/home_project4_benomad_b2.png";
import en_home_project4_benomad_c from "../../../assets/projects/2024/home2/en/home_project4_benomad_c.png";
import en_home_project4_benomad_c2 from "../../../assets/projects/2024/home2/en/home_project4_benomad_c2.png";

// Importing images for project1 (Trenes Argentinos)
import es_home_project1_trenes_a from "../../../assets/projects/2024/home2/es/home_proyecto1_trenes_a.png";
import es_home_project1_trenes_a2 from "../../../assets/projects/2024/home2/es/home_proyecto1_trenes_a2.png";
import es_home_project1_trenes_b from "../../../assets/projects/2024/home2/es/home_proyecto1_trenes_b.png";
import es_home_project1_trenes_b2 from "../../../assets/projects/2024/home2/es/home_proyecto1_trenes_b2.png";
import es_home_project1_trenes_c from "../../../assets/projects/2024/home2/es/home_proyecto1_trenes_c.png";
import es_home_project1_trenes_c2 from "../../../assets/projects/2024/home2/es/home_proyecto1_trenes_c2.png";

// Importing images for project2 (CryptoMize Yourself with Satoshi Tango!)
import es_home_project2_satoshi_a from "../../../assets/projects/2024/home2/es/home_proyecto2_satoshi_a.png";
import es_home_project2_satoshi_a2 from "../../../assets/projects/2024/home2/es/home_proyecto2_satoshi_a2.png";
import es_home_project2_satoshi_b from "../../../assets/projects/2024/home2/es/home_proyecto2_satoshi_b.png";
import es_home_project2_satoshi_b2 from "../../../assets/projects/2024/home2/es/home_proyecto2_satoshi_b2.png";
import es_home_project2_satoshi_c from "../../../assets/projects/2024/home2/es/home_proyecto2_satoshi_c.png";
import es_home_project2_satoshi_c2 from "../../../assets/projects/2024/home2/es/home_proyecto2_satoshi_c2.png";

// Importing images for project3 (Zurich Santander Argentina)
import es_home_project3_zs_a from "../../../assets/projects/2024/home2/es/home_proyecto3_zs_a.png";
import es_home_project3_zs_a2 from "../../../assets/projects/2024/home2/es/home_proyecto3_zs_a2.png";
import es_home_project3_zs_b from "../../../assets/projects/2024/home2/es/home_proyecto3_zs_b.png";
import es_home_project3_zs_b2 from "../../../assets/projects/2024/home2/es/home_proyecto3_zs_b2.png";
import es_home_project3_zs_c from "../../../assets/projects/2024/home2/es/home_proyecto3_zs_c.png";
import es_home_project3_zs_c2 from "../../../assets/projects/2024/home2/es/home_proyecto3_zs_c2.png";

// Importing images for project4 (BeNomad)
import es_home_project4_benomad_a from "../../../assets/projects/2024/home2/es/home_proyecto4_benomad_a.png";
import es_home_project4_benomad_a2 from "../../../assets/projects/2024/home2/es/home_proyecto4_benomad_a2.png";
import es_home_project4_benomad_b from "../../../assets/projects/2024/home2/es/home_proyecto4_benomad_b.png";
import es_home_project4_benomad_b2 from "../../../assets/projects/2024/home2/es/home_proyecto4_benomad_b2.png";
import es_home_project4_benomad_c from "../../../assets/projects/2024/home2/es/home_proyecto4_benomad_c.png";
import es_home_project4_benomad_c2 from "../../../assets/projects/2024/home2/es/home_proyecto4_benomad_c2.png";
import ImageSwitcher from "./ImageSwitcher";

const Projects = () => {
  const [project, setProject] = useState("Benomad");
  const { language } = useContext(UserProvider);

  const imagePaths = {
    en: {
      trenes: {
        a: en_home_project1_trenes_a,
        a2: en_home_project1_trenes_a2,
        b: en_home_project1_trenes_b,
        b2: en_home_project1_trenes_b2,
        c: en_home_project1_trenes_c,
        c2: en_home_project1_trenes_c2,
        link: "/projects/trenesargentinos",
      },
      staoshi: {
        a: en_home_project2_satoshi_a,
        a2: en_home_project2_satoshi_a2,
        b: en_home_project2_satoshi_b,
        b2: en_home_project2_satoshi_b2,
        c: en_home_project2_satoshi_c,
        c2: en_home_project2_satoshi_c2,
        link: "/projects/satoshi",
      },
      zurich: {
        a: en_home_project3_zs_a,
        a2: en_home_project3_zs_a2,
        b: en_home_project3_zs_b,
        b2: en_home_project3_zs_b2,
        c: en_home_project3_zs_c,
        c2: en_home_project3_zs_c2,
        link: "/projects/zurich",
      },
      benomad: {
        a: en_home_project4_benomad_a,
        a2: en_home_project4_benomad_a2,
        b: en_home_project4_benomad_b,
        b2: en_home_project4_benomad_b2,
        c: en_home_project4_benomad_c,
        c2: en_home_project4_benomad_c2,
        link: "/projects/benomad",
      },
    },
    es: {
      trenes: {
        a: es_home_project1_trenes_a,
        a2: es_home_project1_trenes_a2,
        b: es_home_project1_trenes_b,
        b2: es_home_project1_trenes_b2,
        c: es_home_project1_trenes_c,
        c2: es_home_project1_trenes_c2,
        link: "/projects/trenesargentinos",
      },
      staoshi: {
        a: es_home_project2_satoshi_a,
        a2: es_home_project2_satoshi_a2,
        b: es_home_project2_satoshi_b,
        b2: es_home_project2_satoshi_b2,
        c: es_home_project2_satoshi_c,
        c2: es_home_project2_satoshi_c2,
        link: "/projects/satoshi",
      },
      zurich: {
        a: es_home_project3_zs_a,
        a2: es_home_project3_zs_a2,
        b: es_home_project3_zs_b,
        b2: es_home_project3_zs_b2,
        c: es_home_project3_zs_c,
        c2: es_home_project3_zs_c2,
        link: "/projects/zurich",
      },
      benomad: {
        a: es_home_project4_benomad_a,
        a2: es_home_project4_benomad_a2,
        b: es_home_project4_benomad_b,
        b2: es_home_project4_benomad_b2,
        c: es_home_project4_benomad_c,
        c2: es_home_project4_benomad_c2,
        link: "/projects/benomad",
      },
    },
  };

  const listProjects = [
    {
      title: "Benomad",
      className: "benomad",
      pictures: [
        wop_projects_benomad01,
        wop_projects_benomad02,
        wop_projects_benomad03,
        wop_projects_benomad04,
        wop_projects_benomad05,
      ],
      titleContainer: "titleProjectBenomad",
      descriptionContainer: "contenidoProjectBenomad",
      titleTask: "titleServicesNew2",
      descriptionTask: "descriptionBenomad",
    },
    {
      title: "Flip",
      className: "flip",
      pictures: [
        wop_projects_flip01,
        wop_projects_flip02,
        wop_projects_flip03,
        wop_projects_flip04,
        wop_projects_flip05,
      ],
      titleContainer: "titleProjectFlip",
      descriptionContainer: "contenidoProjectFlip",
      titleTask: "titleServicesNew2",
      descriptionTask: "descriptionFlip",
    },
    {
      title: "Trenes",
      className: "trenes",
      pictures: [
        wop_projects_trenes01,
        wop_projects_trenes02,
        wop_projects_trenes03,
        wop_projects_trenes04,
        wop_projects_trenes05,
      ],
      titleContainer: "titleProjectTrenesaArgentinos",
      descriptionContainer: "contenidoProjectTrenesArgentinos",
      titleTask: "titleServicesNew2",
      descriptionTask: "descriptionTrenes",
    },
    {
      title: "Zurich",
      className: "zurich",
      pictures: [wop_projects_zs01, wop_projects_zs02, wop_projects_zs03, wop_projects_zs04, wop_projects_zs05],
      titleContainer: "titleProjectZurich",
      descriptionContainer: "contenidoProjectZurich",
      titleTask: "titleServicesNew2",
      descriptionTask: "descriptionZurich",
    },
  ];

  const renderActiveProjectContainer = (project) => {
    let activeInfo;
    activeInfo = listProjects.filter((e) => e.title === project)[0];

    return (
      <ContainerProject
        title={activeInfo.titleContainer}
        description={activeInfo.descriptionContainer}
        titleTask={activeInfo.titleTask}
        descriptionTask={activeInfo.descriptionTask}
      />
    );
  };

  return (
    <Container fluid className="container_projects p-0 m-0" id="projects">
      <Container className="pb-5 mx-md-auto m-0 px-md-auto p-0">
        <Row className="pt-5 pb-5">
          <Col xxl={5} xs={11} className="mx-auto">
            <p className="text-center pt-5 pb-5">
              <span style={{ fontWeight: 400 }}>
                {" "}
                <ReactTranslated.Translate text="fraseCreativeIntro" />
              </span>{" "}
              <br></br> <ReactTranslated.Translate text="fraseCreativeIntro2" />
            </p>
          </Col>
        </Row>
        <Row>
          <Col xxl={5} xs={11} className="mx-auto pb-5">
            <Link to="/projects" className="linkNext">
              {language === "en" ? (
                <img src={projectsTitle} alt="WOP" className="img-fluid" />
              ) : (
                <img src={projectsTitle_es} alt="WOP" className="img-fluid" />
              )}
            </Link>
          </Col>
        </Row>
        <Row className="pt-5 pb-3">
          <Col md={7} xs={11} className="mx-auto">
            {/* <Row>
              {listProjects.map((p) => (
                <Col md={3} xs={12} className="pb-md-0 pb-4">
                  <div
                    className={`boxImgProject ${p.className} ${p.title === project ? "active" : ""}`}
                    onClick={() => setProject(p.title)}
                  ></div>
                </Col>
              ))}
            </Row> */}
            <Row>
              <Col md={6} xs={12}>
                <ImageSwitcher
                  project="trenes"
                  language={language}
                  imagePaths={imagePaths}
                  link={"/projects/trenesargentinos"}
                />
                <div className="p-2"></div>
                <ImageSwitcher
                  project="staoshi"
                  language={language}
                  imagePaths={imagePaths}
                  link={"/projects/satoshi"}
                />
              </Col>
              <Col md={6} xs={12} className="pt-md-0 pt-3">
                <ImageSwitcher project="zurich" language={language} imagePaths={imagePaths} link={"/projects/zurich"} />
                <div className="p-2"></div>
                <ImageSwitcher
                  project="benomad"
                  language={language}
                  imagePaths={imagePaths}
                  link={"/projects/benomad"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <div className="d-md-block d-none">
          <Row className="pt-1 pb-5">
            <Col xxl={10} xs={11} className="mx-auto">
              <ProjectSlider items={listProjects} activeProject={project} activeItem />
            </Col>
          </Row>
          {renderActiveProjectContainer(project)}
        </div> */}

        {
          // PROJECTS SOLO PARA MOOOOOOOOOBILE
        }
        {/* {listProjects.map((p) => (
          <div className="d-block d-md-none">
            <Row className="pt-1 pb-1">
              <Col xxl={10} xs={12} className="mx-auto">
                <ProjectSlider items={listProjects} activeProject={p.title} activeItem />
              </Col>
            </Row>
            {renderActiveProjectContainer(p.title)}
          </div>
        ))} */}
        <Row>
          <Col className="text-center pt-5 pb-4">
            <Link to="/projects" className="linkNext">
              <ReactTranslated.Translate text="vermasproyectos" /> {" >>"}
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Projects;
